define("ember-50k/templates/components/local-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lFXWZv7R",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"local-mp4 local-video row\"],[8],[2,\" This is a template for 4by3 video\"],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"title\",\"0\",\"value\"]]],null,{\"statements\":[[0,\"      \"],[7,\"h4\",true],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[1,[23,0,[\"fields\",\"title\",\"0\",\"value\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"embed-responsive embed-responsive-4by3 js-player\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n      \"],[7,\"video\",true],[10,\"class\",\"video-local\"],[10,\"width\",\"4\"],[10,\"height\",\"3\"],[10,\"autoplay\",\"\"],[10,\"loop\",\"\"],[10,\"muted\",\"\"],[10,\"playsinline\",\"\"],[11,\"id\",[29,[\"video-\",[23,0,[\"id\"]]]]],[11,\"src\",[29,[[23,0,[\"fields\",\"mp4\"]]]]],[8],[0,\"\\n        \"],[7,\"source\",true],[11,\"src\",[29,[[23,0,[\"fields\",\"mp4\"]]]]],[10,\"type\",\"video/mp4\"],[8],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"If you are reading this, it is because your browser does not support the HTML5 video element.\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/local-video.hbs"
    }
  });

  _exports.default = _default;
});