define("ember-50k/components/slider-cycle2", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fastboot: Ember.inject.service(),
    intervalMili: Ember.computed('fields.slide_speed_seconds.0.value', function () {
      let {
        intervalSeconds
      } = this;

      if (intervalSeconds == undefined) {
        return 3000;
      } else {
        return intervalSeconds * 1000;
      }
    }) // didInsertElement() {
    //   if (this.fastboot.isFastBoot) { return; } 
    //   else {
    //     $.getScript('/assets/cycle2.js').done(function() {
    //       $.getScript('/assets/jquery.cycle2.swipe.min.js');
    //       // Make sure the slider loads before doing the lazyloading
    //       //  to prevent stacked images while slider gets going.  
    //       $('.late-lazyload').each(function(){
    //         $(this).removeClass('late-lazyload');
    //         $(this).addClass('lazyload');
    //       });
    //     });
    //   }
    // }

  });

  _exports.default = _default;
});