define("ember-50k/templates/components/quote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FjTBRQqU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row quote-full\"],[8],[2,\" Component gif.hbs\"],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-lg-9 col-md-11 body\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"body_quote\",\"0\",\"value\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,0,[\"fields\",\"body_quote\",\"0\",\"value\"]],true],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"title_quote\",\"0\",\"value\"]]],null,{\"statements\":[[0,\"      \"],[7,\"h4\",true],[8],[0,\"\\n        \"],[1,[23,0,[\"fields\",\"title_quote\",\"0\",\"value\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12 body-md\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"sub_title_quote\",\"0\",\"value\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[1,[23,0,[\"fields\",\"sub_title_quote\",\"0\",\"value\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/quote.hbs"
    }
  });

  _exports.default = _default;
});