define("ember-50k/templates/components/job-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o34krtJw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"jobPostLink\",[23,0,[\"linktoRoute\"]],[23,0,[\"slug\"]]]],{\"statements\":[[7,\"div\",true],[10,\"class\",\"hoverRow\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row jobPostRow\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"jobPostHeadline\"],[8],[1,[23,0,[\"title\"]],false],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"jobPostDetails\"],[8],[1,[23,0,[\"location\"]],false],[0,\" + \"],[1,[23,0,[\"type\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-3 d-flex align-items-end\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"jobPostDetails\"],[8],[1,[23,0,[\"department\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-3\"],[8],[0,\"\\n      \"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"float-right btn\",\"careers.careers-post\",[23,0,[\"slug\"]]]],{\"statements\":[[0,\"Apply\"]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/job-post.hbs"
    }
  });

  _exports.default = _default;
});