define("ember-50k/components/post-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //items: service('postfilters'),
    didRender() {
      $(document).on('click tap', '#year-btn', function () {
        // $('.PostFilters .categoryButton a.btn').each(function() {
        //     $(this).removeClass('active');
        // });
        // $(this).addClass('active');
        $(this).toggleClass('active');
        $('#year-filter').toggleClass("hide"); // if ($('#year-filter').hasClass("hide")){
        //     $('#year-filter').removeClass('hide');
        //     console.log('remove');
        // }
        // else {
        //     $('#year-filter').addClass('hide');
        //     console.log('added');
        // }
      });
    }

  });

  _exports.default = _default;
});