define("ember-50k/components/article-filters", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',

    // remove wrapping div
    didInsertElement() {
      (0, _jquery.default)(function () {
        (0, _jquery.default)(".categoryLinks .copyCategoryLink:first-child a").addClass("active");
      });
    }

  });

  _exports.default = _default;
});