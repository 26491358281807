define("ember-50k/routes/capabilities", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    classNames: ['capabilities'],

    afterModel(page, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: page.metatags.title,
        description: page.metatags.description,
        url: 'https://www.50000feet.com/capabilities/'
      };

      if (page.metatags.image_src != undefined) {
        this.metaInfo.imgSrc = page.metatags.image_src;
      }

      if (!this.get('fastboot.isFastBoot')) {
        AOS.init();
        (0, _jquery.default)("#body").removeClass("load");
      }

      ;
    },

    model() {
      return this.store.queryRecord('page', {
        slug: 'capabilities'
      });
    }

  });

  _exports.default = _default;
});