define("ember-50k/models/post", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    video: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    image_alt: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    date: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    filter: _emberData.default.attr('string'),
    metatags: _emberData.default.attr(),
    related: _emberData.default.attr(),
    tags: _emberData.default.attr()
  });

  _exports.default = _default;
});