define("ember-50k/templates/terms-and-conditions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nk75da7v",
    "block": "{\"symbols\":[\"value\",\"key\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"terms-and-conditions\"],[10,\"class\",\"container\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"-each-in\",[[23,0,[\"model\",\"paragraphs\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,1,[\"PageIntro\"]]],null,{\"statements\":[[0,\"      \"],[5,\"page-intro\",[],[[\"@id\",\"@fields\",\"@bootstrapClasses\"],[[23,1,[\"id\"]],[23,1,[\"fields\"]],\"col-lg-9 col-md-11\"]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[\"HtmlContent\"]]],null,{\"statements\":[[0,\"      \"],[5,\"html-content\",[],[[\"@id\",\"@fields\",\"@bootstrapClasses\"],[[23,1,[\"id\"]],[23,1,[\"fields\"]],\"col-lg-9 col-md-11 termsSubHead\"]]],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1,2]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"back-to-top col-md-12 text-center\"],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"#body\"],[8],[0,\"\\n        \"],[7,\"svg\",true],[8],[0,\"\\n          \"],[7,\"use\",true],[10,\"xlink:href\",\"#back-top\",\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/terms-and-conditions.hbs"
    }
  });

  _exports.default = _default;
});