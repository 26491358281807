define("ember-50k/helpers/offset-evens", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function offsetEvens(params) {
    let index = params[0];

    if (!(index % 2)) {
      return 'offset-md-1';
    } else return '';
  });

  _exports.default = _default;
});