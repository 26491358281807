define("ember-50k/components/read-more", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender() {
      (0, _jquery.default)("#read-more").click(function () {
        if ((0, _jquery.default)(this).text() == "Read Less") {
          (0, _jquery.default)(this).text("Read Summary");
        } else {
          (0, _jquery.default)(this).text("Read Less");
        }

        ;
      });
    }

  });

  _exports.default = _default;
});