define("ember-50k/templates/components/two-column-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "walv8lqk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"html-content two-column row\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n     \\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"col-lg-6 html-left paragraph-\",[23,0,[\"id\"]]]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"html-card\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"html_left\"]]],null,{\"statements\":[[0,\"                \"],[1,[23,0,[\"fields\",\"html_left\",\"0\",\"value\"]],true],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"col-lg-6 html-right paragraph-\",[23,0,[\"id\"]]]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"html-card\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"html_right\"]]],null,{\"statements\":[[0,\"                \"],[1,[23,0,[\"fields\",\"html_right\",\"0\",\"value\"]],true],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\" \\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/two-column-html.hbs"
    }
  });

  _exports.default = _default;
});