define("ember-50k/components/random", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender() {
      //Add your images, we'll set the path in the next step
      var images = ['https://50000feet-com-files.s3.us-east-2.amazonaws.com/s3fs-public/2019-10/50k-team%403x.jpg', 'https://50000feet-com-files.s3.us-east-2.amazonaws.com/s3fs-public/2019-10/50k-careers.jpg', 'https://50000feet-com-files.s3.us-east-2.amazonaws.com/s3fs-public/2019-09/50k-stairwell%20%281%29.jpg', 'https://50000feet-com-files.s3.us-east-2.amazonaws.com/s3fs-public/2019-10/404.gif']; //Build the img, then do a bit of maths to randomize load and append to a div. Add a touch off css to fade them badboys in all sexy like.

      (0, _jquery.default)('<img class="img-fluid fade-in" src="' + images[Math.floor(Math.random() * images.length)] + '">').appendTo('#banner-load');
    }

  });

  _exports.default = _default;
});