define("ember-50k/routes/work", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    infinity: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    classNames: ['work'],
    queryParams: {
      industry: {
        refreshModel: true
      },
      discipline: {
        refreshModel: true
      },
      view: {
        refreshModel: false
      },
      preserveScrollPosition: {
        refreshModel: false
      }
    },
    view: null,

    afterModel(page, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: 'Work',
        plyr: true,
        url: 'https://www.50000feet.com/work',
        description: 'We know that inspiration can come from anywhere. But in our experience, it’s usually the product of insatiable curiosity and relentless creativity that make brands coveted and companies admired.'
      };

      if (!this.get('fastboot.isFastBoot')) {
        AOS.init();
        (0, _jquery.default)("#body").removeClass("load");
      }

      ;
    },

    model(params) {
      let discipline = params.discipline;
      let industry = params.industry;
      let parameters = {
        perPage: 30,
        startingPage: 1
      };

      if (discipline != undefined) {
        parameters['discipline'] = discipline;
      }

      if (industry != undefined) {
        parameters['industry'] = industry;
      }

      return this.infinity.model('client', parameters);
    }

  });

  _exports.default = _default;
});