define("ember-50k/adapters/application", ["exports", "@ember-data/adapter/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    //host: 'https://admin.50000feet.com',
    host: 'https://backend.50000feet.com',
    namespace: 'api/v1'
  });

  _exports.default = _default;
});