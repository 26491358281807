define("ember-50k/templates/components/work-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mjfoX46c",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[4,\"each\",[[23,0,[\"filters\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isDiscipline\"]]],null,{\"statements\":[[0,\"            \"],[4,\"link-to\",null,[[\"query\",\"route\"],[[28,\"hash\",null,[[\"discipline\",\"industry\",\"preserveScrollPosition\"],[[23,1,[\"name\"]],\"\",true]]],\"work\"]],{\"statements\":[[1,[23,1,[\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"isIndustry\"]]],null,{\"statements\":[[0,\"            \"],[4,\"link-to\",null,[[\"query\",\"route\"],[[28,\"hash\",null,[[\"industry\",\"discipline\",\"preserveScrollPosition\"],[[23,1,[\"name\"]],\"\",true]]],\"work\"]],{\"statements\":[[1,[23,1,[\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/work-filters.hbs"
    }
  });

  _exports.default = _default;
});