define("ember-50k/components/tall-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fastboot: Ember.inject.service(),
    intervalMili: Ember.computed('fields.slide_speed_seconds.0.value', function () {
      let {
        intervalSeconds
      } = this;

      if (intervalSeconds == undefined) {
        return 3000;
      } else {
        return intervalSeconds * 1000;
      }
    }),
    mobileRowClasses: Ember.computed('mobile', function () {
      let {
        mobile
      } = this;

      if (mobile == "0" || !mobile) {
        return 'd-none d-lg-block';
      } else {
        return 'd-lg-none';
      }
    }),
    isMobile: Ember.computed('mobile', function () {
      let {
        mobile
      } = this;

      if (mobile == "0" || !mobile) {
        return false;
      } else {
        return true;
      }
    }),
    ratioClass: Ember.computed('mobile', function () {
      let {
        mobile
      } = this;

      if (mobile == "0" || !mobile) {
        return 'intrinsic--4x3';
      } else {
        return 'intrinsic--4x3';
      }
    })
  });

  _exports.default = _default;
});