define("ember-50k/routes/index", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    classNames: ['home'],

    afterModel(page, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: page.metatags.title,
        description: page.metatags.description,
        url: 'https://www.50000feet.com/'
      };

      if (page.metatags.image_src != undefined) {
        this.metaInfo.imgSrc = page.metatags.image_src;
      }

      if (!this.get('fastboot.isFastBoot')) {
        _jquery.default.getScript('/assets/jquery.cycle2.swipe.min.js');

        _jquery.default.getScript('/assets/cycle2.js').done(function () {
          // Make sure the slider loads before doing the lazyloading
          //  to prevent stacked images while slider gets going.
          (0, _jquery.default)('.late-lazyload').each(function () {
            (0, _jquery.default)(this).removeClass('late-lazyload');
            (0, _jquery.default)(this).addClass('lazyload');
          });
        });
      }

      ;

      if (!this.get('fastboot.isFastBoot')) {
        AOS.init();
        (0, _jquery.default)("#body").removeClass("load");
      }

      ;
    },

    model() {
      return this.store.queryRecord('page', {
        slug: 'home'
      });
    },

    didRender() {
      this._super(...arguments);

      let video = this.element.querySelector('video');

      if (video) {
        video.muted = true;
        video.play();
      }
    }

  });

  _exports.default = _default;
});