define("ember-50k/components/post-ticker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),

    willRender() {
      if (!this.get('done')) {
        this.set('done', true);
        this.store.query('post', {
          type: this.postType,
          limit: 5
        }).then(data => {
          var topfive = data.slice(0, 5);
          this.set('posts', topfive);
        });
      }
    },

    postTypeLabel: Ember.computed('postType', function () {
      let {
        postType
      } = this;
      return postType.charAt(0).toUpperCase() + postType.slice(1);
    })
  });

  _exports.default = _default;
});