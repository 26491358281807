define("ember-50k/routes/views/index", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    infinity: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    classNames: ['ideas'],
    queryParams: {
      category: {
        refreshModel: true
      }
    },

    afterModel(post, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: 'Ideas',
        slug: 'views',
        description: "We like to say that perspective is everything, but even more so, it’s what you do with your perspective that really counts.",
        url: 'https://www.50000feet.com/views'
      };

      if (!this.get('fastboot.isFastBoot')) {
        AOS.init();
        (0, _jquery.default)("#body").removeClass("load");
      }

      ;
    },

    model(params) {
      let category = params.category;
      let parameters = {
        type: 'ideas',
        perPage: 6,
        startingPage: 1
      };

      if (category != undefined) {
        parameters['category'] = category;
      }

      return this.infinity.model('post', parameters);
    }

  });

  _exports.default = _default;
});