define("ember-50k/components/work-filters", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    tagName: '',

    // remove wrapping div
    willRender() {
      this.store.findAll(this.filterType).then(data => {
        if (this.filterType == 'discipline') {
          this.set('isDiscipline', true);
        } else {
          this.set('isIndustry', true);
        }

        this.set('filters', data);
      });
    },

    didInsertElement() {
      (0, _jquery.default)(document).on('click', '.categoryLink', function () {
        var selected_filter = (0, _jquery.default)(this).data('category-link');
        (0, _jquery.default)('.content-filter.row').each(function () {
          var filter = (0, _jquery.default)(this).data('filter');

          if (filter != selected_filter) {
            (0, _jquery.default)(this).addClass('d-none');
          } else {
            (0, _jquery.default)(this).removeClass('d-none');
          }
        });
      });
    }

  });

  _exports.default = _default;
});