define("ember-50k/routes/news/index", ["exports", "jquery", "ember-50k/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    infinity: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    classNames: ['news'],
    queryParams: {
      category: {
        refreshModel: true
      }
    },

    afterModel(post, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: 'News',
        slug: 'news',
        description: "We like to say that perspective is everything, but even more so, it’s what you do with your perspective that really counts.",
        url: 'https://www.50000feet.com/news'
      };

      if (!this.get('fastboot.isFastBoot')) {
        AOS.init();
        (0, _jquery.default)("#body").removeClass("load");
      }

      ;
    },

    init() {
      this._super(...arguments);
    },

    model(params) {
      let category = params.category;
      console.log(_environment.default);
      let parameters = {
        type: 'news',
        perPage: 12,
        // visibleTiles
        startingPage: 1
      };

      if (_environment.default['isStage'] == true) {
        parameters['environment'] = 'stage';
      }

      if (category != undefined) {
        parameters['category'] = category;
      }

      return this.infinity.model('post', parameters);
    }

  });

  _exports.default = _default;
});