define("ember-50k/models/solutions", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    slug: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    paragraphs: _emberData.default.attr(),
    summary: _emberData.default.attr(),
    description: _emberData.default.attr(),
    intro: _emberData.default.attr()
  });

  _exports.default = _default;
});