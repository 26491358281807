define("ember-50k/routes/news/news-post", ["exports", "jquery", "ember-50k/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    classNames: ['news-post'],

    afterModel(post, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: post.title,
        slug: post.slug,
        description: post.summary,
        url: 'https://www.50000feet.com/news/' + post.slug + '/',
        imgSrc: post.image
      };

      if (!this.get('fastboot.isFastBoot')) {
        AOS.init();
        (0, _jquery.default)("#body").removeClass("load");
      }

      ;
    },

    model(params) {
      var parameters = {
        slug: params.slug
      };

      if (_environment.default['isStage'] == true) {
        parameters['environment'] = 'stage';
      }

      return this.store.queryRecord('post', parameters);
    }

  });

  _exports.default = _default;
});