define("ember-50k/templates/components/structured-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vUk9AdiW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"script\",true],[10,\"type\",\"application/ld+json\"],[8],[1,[23,0,[\"fields\",\"structured_data\"]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/structured-data.hbs"
    }
  });

  _exports.default = _default;
});