define("ember-50k/templates/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TgrOdL9+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-not-found container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"stupid-big-font\"],[8],[0,\"404\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[8],[0,\"\\n        Error 404\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"body row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-lg-9 col-md-11\"],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[0,\"\\n        The page you were looking for doesn’t exist. You may have\\n        mistyped the address or the page may have moved.\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"button-wrapper\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn\",\"work\"]],{\"statements\":[[0,\"          View Work\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn\",\"contact\"]],{\"statements\":[[0,\"          Get in Touch\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/404.hbs"
    }
  });

  _exports.default = _default;
});