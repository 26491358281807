define("ember-50k/templates/components/work-thumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MC6ty7LF",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[4,\"if\",[[23,0,[\"clients\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[7,\"hr\",true],[8],[9],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ArticleTitle col-md-12\"],[8],[0,\"More in \"],[1,[23,0,[\"firstIndustry\"]],false],[9],[0,\"\\n\"],[4,\"each\",[[23,0,[\"clients\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\",true],[11,\"href\",[29,[[23,1,[\"slug\"]]]]],[10,\"class\",\"thumb-item col-lg-3 col-md-6 col-sm-12\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"article-card-hover\"],[8],[0,\"\\n            \"],[7,\"img\",true],[10,\"style\",\"width:100%;\"],[11,\"src\",[29,[[28,\"img-handler\",[[23,1,[\"image\"]],\"510\",\"292\"],null]]]],[8],[9],[0,\"\\n            \"],[7,\"h5\",true],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[7,\"hr\",true],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/work-thumbs.hbs"
    }
  });

  _exports.default = _default;
});