define("ember-50k/templates/components/read-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6eE3VmWc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-lg-9 col-md-11 collapse\"],[10,\"id\",\"collapseSummary\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"clientPage client-intro-copy\"],[10,\"style\",\"margin-bottom: 90px;\"],[8],[0,\"\\n          \"],[1,[23,0,[\"fields\",\"html_field\",\"0\",\"value\"]],true],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"id\",\"read-more\"],[10,\"class\",\"btn\"],[10,\"data-toggle\",\"collapse\"],[10,\"data-target\",\"#collapseSummary\"],[10,\"aria-expanded\",\"false\"],[10,\"aria-controls\",\"collapseSummary\"],[8],[0,\"\\n        Read Summary\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/read-more.hbs"
    }
  });

  _exports.default = _default;
});