define("ember-50k/templates/components/social", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ob/zHEi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"bio-share share\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"linkedin_url\",\"0\",\"value\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[10,\"aria-label\",\"LinkedIn Share\"],[10,\"class\",\"a2a_button_linkedin\"],[11,\"href\",[29,[[23,0,[\"fields\",\"linkedin_url\",\"0\",\"value\"]]]]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noreferrer\"],[8],[0,\"\\n      \"],[7,\"svg\",true],[8],[0,\"\\n        \"],[7,\"use\",true],[10,\"xlink:href\",\"#linkedin\",\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"twitter_url\",\"0\",\"value\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[10,\"aria-label\",\"Twitter Share\"],[10,\"class\",\"a2a_button_twitter\"],[11,\"href\",[29,[[23,0,[\"fields\",\"twitter_url\",\"0\",\"value\"]]]]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noreferrer\"],[8],[0,\"\\n      \"],[7,\"svg\",true],[8],[0,\"\\n        \"],[7,\"use\",true],[10,\"xlink:href\",\"#twitter\",\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"email\",\"0\",\"value\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[10,\"aria-label\",\"Email Share\"],[10,\"class\",\"a2a_button_email\"],[11,\"href\",[29,[\"mailto:\",[23,0,[\"fields\",\"email\",\"0\",\"value\"]]]]],[10,\"target\",\"_blank\"],[8],[0,\"\\n      \"],[7,\"svg\",true],[8],[0,\"\\n        \"],[7,\"use\",true],[10,\"xlink:href\",\"#mail\",\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/social.hbs"
    }
  });

  _exports.default = _default;
});