define("ember-50k/components/page-intro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bootstrapColumnClasses: Ember.computed('bootstrapClasses', function () {
      let {
        bootstrapClasses
      } = this;

      if (bootstrapClasses == undefined) {
        return 'col-lg-9 col-md-11';
      } else {
        return bootstrapClasses;
      }
    })
  });

  _exports.default = _default;
});