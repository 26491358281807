define("ember-50k/routes/terms-and-conditions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    classNames: ['terms-and-conditions'],
    activate: function () {
      this._super();
    },

    afterModel(page, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: 'Terms and Privacy',
        url: 'https://www.50000feet.com/terms-and-conditions'
      };
    },

    model() {
      return this.store.queryRecord('page', {
        slug: 'terms-and-conditions'
      });
    }

  });

  _exports.default = _default;
});