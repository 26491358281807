define("ember-50k/services/postfilters", ["exports", "ember-apollo-client", "ember-50k/queries/getPosts.graphql"], function (_exports, _emberApolloClient, _getPosts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    apollo: (0, _emberApolloClient.queryManager)(),
    result: null,

    init() {
      this._super(...arguments); //vars = { 
      //  yearStart: '1577836800',
      //  yearEnd:   '1609459200',
      //};
      //this.set('items', this.apollo.watchQuery({query}));
      //this.result = this.apollo.watchQuery({query: getPosts});


      this.result = this.apollo.query({
        query: _getPosts.default,
        ssr: false
      }).then(data => {
        return data.nodeQuery.entities;
      });
    },

    willDestroy() {
      (0, _emberApolloClient.unsubscribe)(this.result);
    }

  });

  _exports.default = _default;
});