define("ember-50k/helpers/res-img-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function resImgUrl(params) {
    let url = params[0];

    if (url == undefined) {
      return '';
    }

    if (url.indexOf('.gif') !== -1) {
      // skip resizing .gif files for the moment.
      return url;
    }

    if (params[2] != undefined) {
      url = url.substr(0, url.lastIndexOf('.')) + '.' + params[2];
    } // add the resize instructions to the cloudfront url.


    let asset_location = `https://dygx83j2yp3p6.cloudfront.net/resized/${params[1]}/`; // replace the bucket domain with the cloudfront URL and the resize instructions.

    return url.replace('https://50000feet-com-files.s3.us-east-2.amazonaws.com/', asset_location);
  });

  _exports.default = _default;
});