define("ember-50k/helpers/inc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function inc(params) {
    let index = params[0];
    return index + 1;
  });

  _exports.default = _default;
});