define("ember-50k/routes/client", ["exports", "jquery", "ember-50k/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    classNames: ['client'],

    afterModel(client, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: client.title,
        plyr: true,
        url: 'https://www.50000feet.com/client/' + client.slug + '/',
        description: client.description,
        imgSrc: client.image
      };

      if (!this.get('fastboot.isFastBoot')) {
        (0, _jquery.default)(document).ready(function () {
          _jquery.default.getScript('/assets/plyr.js').done(function () {
            var options = {
              vimeo: {
                dnt: true,
                maxwidth: '1110'
              }
            };
            var players = Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p, options));
          });
        });
      }

      ;

      if (!this.get('fastboot.isFastBoot')) {
        _jquery.default.getScript('/assets/jquery.cycle2.swipe.min.js');

        _jquery.default.getScript('/assets/cycle2.js').done(function () {
          // Make sure the slider loads before doing the lazyloading
          //  to prevent stacked images while slider gets going.
          (0, _jquery.default)('.late-lazyload').each(function () {
            (0, _jquery.default)(this).removeClass('late-lazyload');
            (0, _jquery.default)(this).addClass('lazyload');
            AOS.refresh();
          });
        });

        AOS.init();
        document.addEventListener('lazyloaded', e => {
          AOS.refresh();
        });
        (0, _jquery.default)("#body").removeClass("load");
      }

      ;
    },

    model(params) {
      let parameters = {
        slug: params.slug
      };

      if (_environment.default['isStage'] == true) {
        parameters['environment'] = 'stage';
      }

      return this.store.queryRecord('client', parameters);
    }

  });

  _exports.default = _default;
});