define("ember-50k/router", ["exports", "ember-50k/config/environment", "ember-router-scroll"], function (_exports, _environment, _emberRouterScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route("news", function () {
      this.route("index", {
        path: "/"
      });
      this.route("news-post", {
        path: "/:slug"
      });
    });
    this.route("careers", function () {
      this.route("index", {
        path: "/"
      });
      this.route("careers-post", {
        path: "/:slug"
      });
    });
    this.route("team", function () {
      this.route("index", {
        path: "/"
      });
      this.route("team-bio", {
        path: "/:slug"
      });
    }); // Work Pages

    this.route("work"); // Pages

    this.route("client", {
      path: "/client/:slug"
    });
    this.route("about");
    this.route("contact");
    this.route("terms-and-conditions");
    this.route("community");
    this.route("index", {
      path: "/"
    }); // Test Capabilities Page

    this.route("test-capa");
    this.route("capabilities"); //This will become the new solutions page and will not longer be used post launch

    this.route("qajew9famg2unxp3"); // CSA Page
    //this.route("carbone-smolan-new-york");
    // DMC Page

    this.route("design-museum-of-chicago"); // Industries

    this.route('industries', {
      path: "industries/"
    });
    this.route('industries'); // Solutions 

    this.route('solutions');
    this.route('sales-enablement');
    this.route('brand-transition-planning');
    this.route('content-studio');
    this.route('talent-acquisitions');
    this.route('sonic-branding');
    this.route('branding-commemorations');
    this.route('naming'); // Industries

    this.route('financial-services');
    this.route('professional-services');
    this.route('healthcare'); //404 Route needs to be the last route entry in this list

    this.route("200", {
      path: ":wildcard"
    });
  });
  var _default = Router;
  _exports.default = _default;
});