define("ember-50k/templates/components/html-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L5hH2un4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"html-content row\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"bootstrapColumnClasses\"],\" paragraph-\",[23,0,[\"id\"]]]]],[8],[0,\"\\n        \"],[1,[23,0,[\"fields\",\"html_field\",\"0\",\"value\"]],true],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/html-content.hbs"
    }
  });

  _exports.default = _default;
});