define("ember-50k/components/work-thumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service('store'),

    willRender() {
      if (!this.get('done')) {
        this.set('done', true);
        var params = {
          limit: 4,
          perPage: 4,
          startingPage: 1
        };

        if (this.industry) {
          var first_industry = this.industry.split(" + ")[0];
          params['industry'] = first_industry;
          this.set('firstIndustry', first_industry);
        }

        if (this.discipline) {
          params['discipline'] = this.discipline;
        }

        this.store.query('client', params).then(data => {
          var topfive = data.slice(0, 4);
          this.set('clients', topfive);
        });
      }
    }

  });

  _exports.default = _default;
});