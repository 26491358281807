define("ember-50k/helpers/img-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // {{img-handler URL FORMAT WIDTH HEIGHT}}
  var _default = Ember.Helper.helper(function imgHandler(params) {
    let url = params[0];
    let width = params[1] || false;
    let height = params[2] || false;
    let format = params[3] || false;

    if (Array.isArray(url)) {
      url = url[0];
    }

    var handlerHost = 'https://d2l5z231ciu7tz.cloudfront.net/';
    var originalHost = 'https://50000feet-com-files.s3.us-east-2.amazonaws.com/';
    var key = url.replace(originalHost, '');

    if (url == undefined) {
      return '';
    } // Skip resizing .gif files for the moment.


    if (url.indexOf('.gif') !== -1) {
      return url;
    } // Check if this should use the original extension.


    if (!format) {
      format = url.split('.').pop();
    }

    var params = {
      "bucket": "50000feet-com-files",
      "key": decodeURIComponent(key),
      "edits": {
        "resize": {
          "fit": "cover"
        },
        "toFormat": format
      }
    };

    if (width) {
      params["edits"]["resize"]["width"] = params[2];
    }

    if (height) {
      params["edits"]["resize"]["height"] = params[3];
    }

    var paramsJSON = encodeURIComponent(JSON.stringify(params));
    return `${handlerHost}${paramsJSON}`;
  });

  _exports.default = _default;
});