define("ember-50k/templates/components/random", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ddKOubLo",
    "block": "{\"symbols\":[],\"statements\":[[2,\"Little CSS fade in \"],[0,\"\\n\"],[7,\"style\",true],[8],[0,\"\\n  .fade-in{\\n    -webkit-animation: fade-in 2s ease;\\n    -moz-animation: fade-in ease-in-out 2s both;\\n    -ms-animation: fade-in ease-in-out 2s both;\\n    -o-animation: fade-in ease-in-out 2s both;\\n    animation: fade-in 2s ease;\\n    visibility: visible;\\n    -webkit-backface-visibility: hidden;\\n  }\\n\\n  @-webkit-keyframes fade-in{0%{opacity:0;} 100%{opacity:1;}}\\n  @-moz-keyframes fade-in{0%{opacity:0} 100%{opacity:1}}\\n  @-o-keyframes fade-in{0%{opacity:0} 100%{opacity:1}}\\n  @keyframes fade-in{0%{opacity:0} 100%{opacity:1}}\\n\\n\"],[9],[0,\"\\n\\n\"],[2,\"We append on this div\"],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"banner-load\"],[8],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/random.hbs"
    }
  });

  _exports.default = _default;
});