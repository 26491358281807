define("ember-50k/templates/components/gif", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f/VUf2hY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"row gif \",[23,0,[\"fields\",\"image_s_\",\"0\",\"size\",\"0\",\"value\"]]]]],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[2,\" Component gif.hbs\"],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"title\",\"0\",\"value\"]]],null,{\"statements\":[[0,\"      \"],[7,\"h4\",true],[8],[1,[23,0,[\"fields\",\"title\",\"0\",\"value\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"img\",true],[10,\"alt\",\"\"],[10,\"class\",\"img-fluid\"],[11,\"src\",[29,[[23,0,[\"fields\",\"image_s_\",\"0\",\"url\"]]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"html_field\",\"0\",\"value\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-9\"],[8],[0,\"\\n        \"],[7,\"p\",true],[8],[1,[23,0,[\"fields\",\"html_field\",\"0\",\"value\"]],true],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/gif.hbs"
    }
  });

  _exports.default = _default;
});