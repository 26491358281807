define("ember-50k/components/video-autoplay", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fastboot: Ember.inject.service(),
    edgeClass: Ember.computed("edge", function () {
      let {
        edge
      } = this;

      if (edge == undefined) {
        return '';
      } else return 'edge';
    }),
    youtube: Ember.computed("videoType", function () {
      let {
        videoType
      } = this;
      return videoType == "youtube";
    }),
    vimeo: Ember.computed("videoType", function () {
      let {
        videoType
      } = this;
      return videoType == "vimeo";
    }),
    bootstrapColumnClasses: Ember.computed('bootstrapClasses', function () {
      let {
        bootstrapClasses
      } = this;

      if (bootstrapClasses == undefined) {
        return 'col-md-12';
      } else {
        return bootstrapClasses;
      }
    }),

    didInsertElement() {
      if (this.fastboot.isFastBoot) {
        return;
      } else {
        _jquery.default.getScript('/assets/plyr.js').done(function () {
          var options = {
            vimeo: {
              dnt: true,
              maxwidth: '1110'
            }
          };
          var players = Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p, options));
        });
      }
    }

  });

  _exports.default = _default;
});