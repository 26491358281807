define("ember-50k/routes/404", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    classNames: ['page-page-not-found'],

    afterModel(page, transition) {
      if (!this.get('fastboot.isFastBoot')) {
        AOS.init();
        (0, _jquery.default)("#body").removeClass("load");
      }

      ;
    }

  });

  _exports.default = _default;
});