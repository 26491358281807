define("ember-50k/queries/getPosts.graphql", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "variableDefinitions": [],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "nodeQuery"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "limit"
            },
            "value": {
              "kind": "IntValue",
              "value": "9999"
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "filter"
            },
            "value": {
              "kind": "ObjectValue",
              "fields": [{
                "kind": "ObjectField",
                "name": {
                  "kind": "Name",
                  "value": "conditions"
                },
                "value": {
                  "kind": "ListValue",
                  "values": [{
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "field"
                      },
                      "value": {
                        "kind": "StringValue",
                        "value": "type",
                        "block": false
                      }
                    }, {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "value"
                      },
                      "value": {
                        "kind": "StringValue",
                        "value": "post",
                        "block": false
                      }
                    }, {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "operator"
                      },
                      "value": {
                        "kind": "EnumValue",
                        "value": "EQUAL"
                      }
                    }]
                  }, {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "field"
                      },
                      "value": {
                        "kind": "StringValue",
                        "value": "field_post_type",
                        "block": false
                      }
                    }, {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "value"
                      },
                      "value": {
                        "kind": "StringValue",
                        "value": "news",
                        "block": false
                      }
                    }, {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "operator"
                      },
                      "value": {
                        "kind": "EnumValue",
                        "value": "EQUAL"
                      }
                    }]
                  }]
                }
              }]
            }
          }],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "entities"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "InlineFragment",
                  "typeCondition": {
                    "kind": "NamedType",
                    "name": {
                      "kind": "Name",
                      "value": "NodePost"
                    }
                  },
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "uuid"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "nid"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "created"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "fieldUrlSlug"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "fieldCategory"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "entity"
                          },
                          "arguments": [],
                          "directives": [],
                          "selectionSet": {
                            "kind": "SelectionSet",
                            "selections": [{
                              "kind": "InlineFragment",
                              "typeCondition": {
                                "kind": "NamedType",
                                "name": {
                                  "kind": "Name",
                                  "value": "TaxonomyTermCategories"
                                }
                              },
                              "directives": [],
                              "selectionSet": {
                                "kind": "SelectionSet",
                                "selections": [{
                                  "kind": "Field",
                                  "name": {
                                    "kind": "Name",
                                    "value": "uuid"
                                  },
                                  "arguments": [],
                                  "directives": []
                                }, {
                                  "kind": "Field",
                                  "name": {
                                    "kind": "Name",
                                    "value": "name"
                                  },
                                  "arguments": [],
                                  "directives": []
                                }]
                              }
                            }]
                          }
                        }]
                      }
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "fieldPostTitle"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "body"
                      },
                      "arguments": [],
                      "directives": [],
                      "selectionSet": {
                        "kind": "SelectionSet",
                        "selections": [{
                          "kind": "Field",
                          "name": {
                            "kind": "Name",
                            "value": "value"
                          },
                          "arguments": [],
                          "directives": []
                        }]
                      }
                    }]
                  }
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 540
    }
  };
  var _default = doc;
  _exports.default = _default;
});