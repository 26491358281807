define("ember-50k/routes/views/views-post", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    classNames: ['ideas-post'],

    afterModel(post, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: post.title,
        slug: post.slug,
        description: post.summary,
        url: 'https://www.50000feet.com/views/',
        imgSrc: post.image
      };

      if (!this.get('fastboot.isFastBoot')) {
        AOS.init();
        (0, _jquery.default)("#body").removeClass("load");
      }

      ;
    },

    model(params) {
      return this.store.queryRecord('post', {
        slug: params.slug
      });
    }

  });

  _exports.default = _default;
});