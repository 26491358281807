define("ember-50k/templates/components/article-thumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cgzvzDl8",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row ArticleThumb\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ArticleTitle col-md-12\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[1,[23,0,[\"postTypeLabel\"]],false],[9],[0,\"\\n\"],[4,\"each\",[[23,0,[\"posts\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"thumb-tile article-card-hover col-lg-3 col-md-6 col-sm-12\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[[23,0,[\"linktoRoute\"]],[23,1,[\"slug\"]]]],{\"statements\":[[0,\"        \"],[7,\"figure\",true],[8],[0,\"\\n            \"],[7,\"picture\",true],[8],[0,\"\\n            \"],[7,\"img\",true],[10,\"style\",\"width:100%;\"],[11,\"src\",[29,[[28,\"img-handler\",[[23,1,[\"image\"]],\"510\",\"292\",false],null]]]],[8],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n            \"],[7,\"h4\",true],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/article-thumb.hbs"
    }
  });

  _exports.default = _default;
});