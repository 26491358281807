define("ember-50k/helpers/late-lazyload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function lateLazyload(params) {
    let index = params[0];

    if (index == 0) {
      return 'lazyload';
    } else return 'late-lazyload';
  });

  _exports.default = _default;
});