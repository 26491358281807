define("ember-50k/components/header", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender() {
      var prevScrollpos = window.pageYOffset;

      window.onscroll = function () {
        var currentScrollPos = window.pageYOffset;

        if (prevScrollpos >= currentScrollPos) {
          document.getElementById("outer-nav-wrapper").style.top = "0";
        } else if (window.scrollY == 0) {
          document.getElementById("outer-nav-wrapper").style.top = "0";
        } else {
          document.getElementById("outer-nav-wrapper").style.top = "-91px";
        }

        prevScrollpos = currentScrollPos;
      };

      (0, _jquery.default)(".toggle-btn").click(function () {
        (0, _jquery.default)("#nav-wrapper-mobile").toggleClass("closed");
        (0, _jquery.default)(".mobile.menu-wrapper").toggleClass("hide");
        (0, _jquery.default)("#body").toggleClass("active");
        (0, _jquery.default)("#nav-wrapper-mobile").toggleClass("active");
        (0, _jquery.default)("#outer-nav-wrapper").toggleClass("active");
      });
      (0, _jquery.default)(function () {
        (0, _jquery.default)('nav a[href^="/' + location.pathname.split("/")[1] + '"]').addClass('active');
      });
    }

  });

  _exports.default = _default;
});