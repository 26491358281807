define("ember-50k/models/bio", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    bio: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    image_alt: _emberData.default.attr('string'),
    hover: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    paragraphs: _emberData.default.attr()
  });

  _exports.default = _default;
});