define("ember-50k/components/local-gif", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fastboot: Ember.inject.service(),

    didRender() {
      this._super(...arguments);

      let video = this.element.querySelector('video');

      if (video) {
        video.muted = true;
        video.play();
      }
    }

  });

  _exports.default = _default;
});