define("ember-50k/templates/components/industry-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zwYgnA/M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"industry-card\"],[11,\"id\",[29,[[23,0,[\"fields\",\"internal_link\",\"0\",\"value\"]]]]],[11,\"name\",[29,[[23,0,[\"fields\",\"internal_link\",\"0\",\"value\"]]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"title\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h4\",true],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n          \"],[1,[23,0,[\"fields\",\"title\",\"0\",\"value\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content-wrapper\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n\"],[0,\"\\n        \"],[7,\"img\",true],[10,\"class\",\"img-fluid\"],[11,\"src\",[29,[[23,0,[\"fields\",\"image\"]]]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"articleCardBody\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"body\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"articleCardSummary\"],[8],[1,[23,0,[\"fields\",\"body\",\"0\",\"value\"]],true],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/industry-card.hbs"
    }
  });

  _exports.default = _default;
});