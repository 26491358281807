define("ember-50k/templates/components/edgegallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d6cTCxsM",
    "block": "{\"symbols\":[\"src\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row edge-gallery\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"title\",\"0\",\"value\"]]],null,{\"statements\":[[0,\"      \"],[7,\"h4\",true],[8],[1,[23,0,[\"fields\",\"title\",\"0\",\"value\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[23,0,[\"fields\",\"image\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\",true],[10,\"class\",\"img-fluid edge\"],[11,\"src\",[29,[[23,1,[]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"html_field\",\"0\",\"value\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-lg-9 col-md-11\"],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[23,0,[\"fields\",\"html_field\",\"0\",\"value\"]],true],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/edgegallery.hbs"
    }
  });

  _exports.default = _default;
});