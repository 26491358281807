define("ember-50k/routes/contact", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    classNames: ['contact'],

    afterModel(page, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: 'Contact',
        url: 'https://www.50000feet.com/contact/'
      };

      if (!this.get('fastboot.isFastBoot')) {
        (0, _jquery.default)("#body").removeClass("load"); // move this up here first.

        _jquery.default.getScript('/assets/jquery.cycle2.swipe.min.js');

        _jquery.default.getScript('/assets/cycle2.js').done(function () {
          // Make sure the slider loads before doing the lazyloading
          //  to prevent stacked images while slider gets going.  
          (0, _jquery.default)('.late-lazyload').each(function () {
            (0, _jquery.default)(this).removeClass('late-lazyload');
            (0, _jquery.default)(this).addClass('lazyload');
          });
        });
      }

      if (!this.get('fastboot.isFastBoot')) {
        AOS.init();
        (0, _jquery.default)("#body").removeClass("load");
      }
    },

    model() {
      return this.store.queryRecord('page', {
        slug: 'contact'
      });
    }

  });

  _exports.default = _default;
});