define("ember-50k/routes/careers/careers-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    classNames: ['careers-post'],

    afterModel(job, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: 'Apply at 50k: ' + job.title,
        url: 'https://www.50000feet.com/careers/' + job.slug
      };

      if (!this.get('fastboot.isFastBoot')) {
        AOS.init();
        $("#body").removeClass("load");
      }

      ;
    },

    model(params) {
      return this.store.queryRecord("job", {
        slug: params.slug
      });
    }

  });

  _exports.default = _default;
});