define("ember-50k/templates/components/numbered-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oN/Mb1Gy",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"NumberedList row\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-lg-9 num-list-intro\"],[8],[0,\"\\n        \"],[7,\"h4\",true],[8],[1,[23,0,[\"fields\",\"title\",\"0\",\"value\"]],true],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[1,[23,0,[\"fields\",\"body\",\"0\",\"value\"]],true],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"each\",[[23,0,[\"fields\",\"copy\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"col-lg-6\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"num-item\"],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"num\"],[8],[0,\"\\n                    \"],[7,\"span\",true],[8],[1,[28,\"inc\",[[23,2,[]]],null],false],[9],[0,\"\\n                \"],[9],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"num-copy\"],[8],[0,\"\\n                    \"],[7,\"span\",true],[8],[1,[23,1,[\"value\"]],true],[9],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/numbered-list.hbs"
    }
  });

  _exports.default = _default;
});