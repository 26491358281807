define("ember-50k/components/widealtcallout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isRightAligned: Ember.computed('rightAligned', function () {
      let {
        rightAligned
      } = this;

      if (rightAligned == "0" || !rightAligned) {
        return false;
      } else {
        return true;
      }
    })
  });

  _exports.default = _default;
});