define("ember-50k/components/quote-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intervalMili: Ember.computed('fields.slide_speed_seconds.0.value', function () {
      let {
        intervalSeconds
      } = this;

      if (intervalSeconds == undefined) {
        return 3000;
      } else {
        return intervalSeconds * 1000;
      }
    })
  });

  _exports.default = _default;
});