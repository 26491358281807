define("ember-50k/templates/components/video-autoplay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Q6LL2Sz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"2000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"embed-responsive embed-responsive-16by9 js-player\"],[8],[0,\"\\n      \"],[7,\"iframe\",true],[10,\"src\",\"https://player.vimeo.com/video/369652729?autoplay=1&color=ffffff&byline=0&portrait=0&loop=1\"],[10,\"allow\",\"autoplay; fullscreen\"],[10,\"allowfullscreen\",\"\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/video-autoplay.hbs"
    }
  });

  _exports.default = _default;
});