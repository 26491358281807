define("ember-50k/components/slider-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender() {// swiper.on('reachEnd', function () {
      //     console.log('end');
      // });
      // swiper.on('reachEnd', function() {
      //     console.log("end")
      // });
      // swiper.on('reachBeginning', function() {
      //     console.log("start")
      // });
      // swiper.on('fromEdge', function() {
      // });
    }

  });

  _exports.default = _default;
});