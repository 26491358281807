define("ember-50k/components/article-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // remove wrapping div
    section: Ember.computed('fields.title.0.value', function () {
      let {
        sectionType
      } = this;

      if (sectionType == 'Ideas') {
        return '/ideas';
      } else {
        return '/news';
      }
    })
  });

  _exports.default = _default;
});