define("ember-50k/routes/test-capa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    classNames: ['capabilities'],

    afterModel(page, transition) {
      this._super(...arguments);

      this.metaInfo = {
        title: page.metatags.title,
        description: page.metatags.description,
        url: 'https://www.50000feet.com/test-capa/'
      };

      if (page.metatags.image_src != undefined) {
        this.metaInfo.imgSrc = page.metatags.image_src;
      }
    },

    model() {
      return this.store.queryRecord('page', {
        slug: 'test-capa'
      });
    }

  });

  _exports.default = _default;
});