define("ember-50k/components/halfgalleryspacetext", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isRightAligned: Ember.computed('rightAligned', function () {
      let {
        rightAligned
      } = this;

      if (rightAligned == "0" || !rightAligned) {
        return false;
      } else {
        return true;
      }
    }),
    isEmpty: Ember.computed('fields.half_content.0.value', function () {
      let {
        half_content
      } = this;

      if (half_content) {
        return '';
      } else return 'no-content'; //comment out "no-content" for testing

    }),

    didRender() {
      this._super(...arguments);

      let video = this.element.querySelector('video');

      if (video) {
        video.muted = true;
        video.play();
      }
    }

  });

  _exports.default = _default;
});