define("ember-50k/templates/components/bullet-point-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZedxAxKk",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"info-copy\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"info-hero col-lg-9 col-md-11 row \"],[8],[0,\"\\n        \"],[7,\"h4\",true],[8],[1,[23,0,[\"fields\",\"section_title\",\"0\",\"value\"]],true],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[1,[23,0,[\"fields\",\"title\",\"0\",\"value\"]],true],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"fields\",\"callouts\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"sub-info col-md-5\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-delay\",\"100\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n                \"],[1,[23,1,[\"value\"]],true],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/bullet-point-section.hbs"
    }
  });

  _exports.default = _default;
});