define("ember-50k/templates/components/post-ticker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7OrdjzSt",
    "block": "{\"symbols\":[\"value\",\"key\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"post-ticker\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[10,\"style\",\"padding-left: 0px;\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[1,[23,0,[\"fields\",\"ticker_title\",\"0\",\"value\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[1,[28,\"log\",[[23,0,[\"recents\"]]],null],false],[0,\"\\n\"],[4,\"each\",[[28,\"-each-in\",[[23,0,[\"posts\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[11,\"href\",[29,[\"/\",[23,0,[\"postType\"]],\"/\",[23,1,[\"slug\"]]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row no-gutters\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n              \"],[1,[23,1,[\"title\"]],true],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"date\"],[8],[0,\"\\n              \"],[1,[23,1,[\"date\"]],true],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-6 summary\"],[8],[0,\"\\n            \"],[1,[23,1,[\"summary\"]],true],[0,\"\\n        \"],[9],[0,\"\\n\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/post-ticker.hbs"
    }
  });

  _exports.default = _default;
});