define("ember-50k/templates/components/client-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PpfWjwoi",
    "block": "{\"symbols\":[\"value\",\"key\"],\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"image-grid row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12 image-grid-title\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"A Few Clients\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"each\",[[28,\"-each-in\",[[23,0,[\"fields\"]]],null]],null,{\"statements\":[[0,\"      \"],[5,\"article-card\",[],[[\"@id\",\"@title\",\"@slug\",\"@image\",\"@linktoRoute\",\"@offsetEvens\",\"@lazy\",\"@summary\"],[[23,2,[]],[23,1,[\"name\"]],[23,1,[\"slug\"]],[23,1,[\"image\"]],\"client\",[28,\"offset-evens\",[[23,2,[]]],null],[23,0,[\"lazy\"]],[23,1,[\"summary\"]]]],{\"statements\":[[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"btn-wrapper\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn\",\"work\"]],{\"statements\":[[0,\"          View All Clients\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/client-cards.hbs"
    }
  });

  _exports.default = _default;
});