define("ember-50k/templates/components/page-intro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dlOp0szo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-intro-body page-intro row\"],[10,\"data-aos\",\"fade-up\"],[10,\"data-aos-duration\",\"1000\"],[10,\"data-aos-easing\",\"ease-out\"],[10,\"data-aos-mirror\",\"false\"],[10,\"data-aos-once\",\"true\"],[10,\"data-aos-anchor-placement\",\"top-bottom\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"bootstrapColumnClasses\"]]]],[8],[0,\"\\n    \"],[7,\"section\",true],[11,\"class\",[29,[\"copyPageTitle paragraph-\",[23,0,[\"id\"]]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"fields\",\"title\",\"0\",\"value\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h1\",true],[8],[1,[23,0,[\"fields\",\"title\",\"0\",\"value\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"fields\",\"body\",\"0\",\"value\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[8],[1,[23,0,[\"fields\",\"body\",\"0\",\"value\"]],true],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-50k/templates/components/page-intro.hbs"
    }
  });

  _exports.default = _default;
});